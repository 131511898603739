import { useState } from 'react';

import {
  NewBottomSheet,
  NewBottomSheetClose,
  NewBottomSheetContent,
  NewBottomSheetHeader,
  NewBottomSheetTitle,
  NewBottomSheetTrigger,
  NewBoxButton,
  Spacer,
} from 'design-system';

import { CouponCard } from '@components';

export const CouponsSelect = ({
  coupons,
  initialSelectedCoupon,
  lessonPeriodText,
  onSelectedCoupon,
  children,
}: {
  coupons: CouponCode[];
  initialSelectedCoupon: CouponCode;
  lessonPeriodText: string;
  onSelectedCoupon: (coupon: CouponCode) => void;
  children: React.ReactNode;
}) => {
  const [selectedCoupon, setSelectedCoupon] = useState(initialSelectedCoupon);

  return (
    <NewBottomSheet>
      <NewBottomSheetTrigger asChild>{children}</NewBottomSheetTrigger>
      <NewBottomSheetContent>
        <NewBottomSheetHeader>
          <NewBottomSheetTitle>받은 쿠폰</NewBottomSheetTitle>
        </NewBottomSheetHeader>
        <div className="flex max-h-[330px] flex-col gap-10 overflow-y-auto">
          {coupons.map((coupon) => {
            const { source } = coupon;

            const isSelected = source === selectedCoupon.source;

            const isDisabled = !coupon.discount.canApply({
              periodText: lessonPeriodText,
            });

            return (
              <CouponCard
                key={coupon.code}
                coupon={coupon}
                onClickCoupon={() => {
                  setSelectedCoupon(coupon);
                }}
                isSelected={isSelected}
                isDisabled={isDisabled}
              />
            );
          })}
        </div>
        <Spacer className="h-24" />
        <NewBottomSheetClose>
          <NewBoxButton
            label="이 쿠폰으로 적용하기"
            fill
            onClick={() => onSelectedCoupon(selectedCoupon)}
          />
        </NewBottomSheetClose>
      </NewBottomSheetContent>
    </NewBottomSheet>
  );
};
