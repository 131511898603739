import { useGetUser } from '@db/collections';
import { NewIcon, Spacer } from 'design-system';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { useGetNonMemberFlag } from '@pages/academy/[id]/application';

import { usePageRoute } from '@hooks/use-handle-url';

export const SignupBenefit = () => {
  const router = useRouter();
  const { user } = useGetUser();

  const handleRoute = usePageRoute('/login');
  // const flag = useFeatureFlagVariantKey('non-member');
  const flag = useGetNonMemberFlag();

  if ((user && user.provider !== 'non-member') || flag !== 'test') return null;

  return (
    <>
      <article
        className="bg-new-DTYellow-100 flex cursor-pointer items-center justify-between rounded-[16px] p-[16px]"
        onClick={() => {
          handleRoute('nonMember', {
            redirectUrl: encodeURIComponent(router.asPath),
          });
        }}
      >
        <div className="flex items-center gap-8">
          <Image
            src="/image-present.png"
            alt="혜택"
            width={152}
            height={150}
            className="h-[40px] w-[40px]"
          />
          <div>
            <p className="text-new-Body1-bold">로그인하고 다양한 혜택을 받으세요!</p>
            <Spacer className="h-[2px]" />
            <p className="text-new-Caption1-medium text-new-gray-600">
              필기, 이벤트 쿠폰 발급 등 다양한 혜택 제공
            </p>
          </div>
        </div>
        <NewIcon icon="arrow-right" size={20} className="fill-new-gray-900" />
      </article>
      <Spacer className="h-32" />
    </>
  );
};
