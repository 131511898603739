import { create } from 'zustand';

export const usePurchaseData = create<{
  academy: Academy | null;
  setAcademy: (academy: Academy) => void;
  selectedTime: SelectedTime | null;
  setselectedTime: (selectedTime: SelectedTime | null) => void;
  selectedRange: SelectedRange | null;
  setSelectedRange: (selectedRange: SelectedRange | null) => void;
  lessonInfo: LessonInfo | null;
  setLessonInfo: (lessonInfo: LessonInfo) => void;
  is전액결제: boolean;
  setIs전액결제: (is전액결제: boolean) => void;
  selectedCoupons: CouponCode[];
  setSelectedCoupons: (selectedCoupons: CouponCode[]) => void;
  discountResult: DiscountReturn;
  setDiscountResult: (discountResult: DiscountReturn) => void;
  discounts: Discount[];
  setDiscounts: (discounts: Discount[]) => void;
  visitorInfo: VisitorData;
  setVisitorInfo: (visitorInfo: VisitorData) => void;
  shuttleBusPreference: ShuttleBusPreference;
  setShuttleBusPreference: (shuttleBusPreference: ShuttleBusPreference) => void;
  paymentMethod: 'keyin' | 'card';
  setPaymentMethod: (paymentMethod: 'keyin' | 'card') => void;
  purchaseAgreement: {
    info: boolean;
    private: boolean;
    refund: boolean;
    age: boolean;
  };
  setPurchaseAgreement: (purchaseAgreement: {
    info: boolean;
    private: boolean;
    refund: boolean;
    age: boolean;
  }) => void;
}>((set) => ({
  academy: null,
  setAcademy: (academy: Academy) => set({ academy }),
  selectedTime: null,
  setselectedTime: (selectedTime: SelectedTime | null) => set({ selectedTime }),
  selectedRange: null,
  setSelectedRange: (selectedRange: SelectedRange | null) => set({ selectedRange }),
  lessonInfo: null,
  is전액결제: false,
  setIs전액결제: (is전액결제: boolean) => set({ is전액결제 }),
  setLessonInfo: (lessonInfo: LessonInfo) => set({ lessonInfo }),
  setSelectedCoupons: (selectedCoupons: CouponCode[]) => set({ selectedCoupons }),
  selectedCoupons: [],
  discountResult: {
    원금: 0,
    할인금: 0,
    운전선생할인금: 0,
    외부할인금: 0,
    최종금: 0,
  },
  setDiscountResult: (discountResult: DiscountReturn) => set({ discountResult }),
  discounts: [],
  setDiscounts: (discounts: Discount[]) => set({ discounts }),
  visitorInfo: { name: '', birthday: '', phoneNum: '' },
  setVisitorInfo: (visitorInfo: VisitorData) => set({ visitorInfo }),
  shuttleBusPreference: 'yes',
  setShuttleBusPreference: (shuttleBusPreference: ShuttleBusPreference) =>
    set({ shuttleBusPreference }),
  paymentMethod: 'card',
  setPaymentMethod: (paymentMethod: 'keyin' | 'card') => set({ paymentMethod }),
  purchaseAgreement: {
    info: false,
    private: false,
    refund: false,
    age: false,
  },
  setPurchaseAgreement: (purchaseAgreement: {
    info: boolean;
    private: boolean;
    refund: boolean;
    age: boolean;
  }) => set({ purchaseAgreement }),
}));
