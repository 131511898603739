import { Fragment } from 'react';

import { useGetUser } from '@db/collections';
import { Spacer } from 'design-system';
import { formatDate } from 'shared-values';
import { useShallow } from 'zustand/react/shallow';

import {
  useMergedLessonInfoOnTest,
  useMergedSelectedTimeOrRangeOnTest,
} from '@components/ShortReservationFunnel/hooks/use-is-reservation-funnel-test';

import { usePurchaseData } from '../Purchase.store';

const Dot = () => (
  <svg width="2" height="3" viewBox="0 0 2 3" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle id="Ellipse 173" cx="1" cy="1.5" r="1" fill="#242523" />
  </svg>
);

export const ReservationInfo = () => {
  const {
    academy,
    selectedTime: funnelSelectedTime,
    selectedRange: funnelSelectedRange,
    lessonInfo: funnelLessonInfo,
  } = usePurchaseData(
    useShallow((state) => ({
      academy: state.academy,
      selectedTime: state.selectedTime,
      selectedRange: state.selectedRange,
      lessonInfo: state.lessonInfo,
    })),
  );

  const { user } = useGetUser();

  const { selectedTime, selectedRange } = useMergedSelectedTimeOrRangeOnTest({
    academyId: academy?.id,
    funnelSelectedTime,
    funnelSelectedRange,
  });

  const lessonInfo = useMergedLessonInfoOnTest({
    academyId: academy?.id,
    funnelLessonInfo,
  });

  if (!academy || !lessonInfo) return null;

  const lessonName = lessonInfo.lessonName;

  const { name: academyName, isSimulation, displayName } = academy;

  return (
    <>
      <h4 className="text-new-Sub-Title text-new-gray-900">예약 정보</h4>
      <Spacer className="h-16" />
      <div className="flex gap-8">
        <div className="text-new-Body2-medium flex w-[62px] flex-col gap-12">
          <span className="text-new-gray-500">예약자명</span>
          <span className="text-new-gray-500">{`예약 ${isSimulation ? '연습장' : '학원'}`}</span>
          <span className="text-new-gray-500">{`예약 ${isSimulation ? '상품' : '수업'}`}</span>
          <span className="text-new-gray-500">예약 날짜</span>
        </div>
        <div className="text-new-Body2-medium flex flex-col gap-12">
          <span className="text-new-gray-900">{user && user.realName ? user.realName : '-'}</span>
          <span className="text-new-gray-900">{isSimulation ? academyName : displayName}</span>
          <span className="text-new-gray-900">{lessonName}</span>
          <span className="text-new-gray-900">
            {selectedTime &&
              (selectedTime.times.length > 1 ? (
                <div className="flex items-center gap-4">
                  {selectedTime.times.map((time, index) => (
                    <Fragment key={time.date.getTime()}>
                      {formatDate(time.date, 'YY.MM.DD')}
                      {index !== selectedTime.times.length - 1 && <Dot />}
                    </Fragment>
                  ))}
                </div>
              ) : (
                `${formatDate(selectedTime.date, 'YY.MM.DD')} ${selectedTime.startEndString}`
              ))}
            {selectedRange &&
              (isSimulation
                ? formatDate(selectedRange.startDate, 'YY.MM.DD')
                : `${formatDate(selectedRange.startDate, 'YY.MM.DD')} ~ ${formatDate(
                    selectedRange.endDate,
                    'YY.MM.DD',
                  )}`)}
          </span>
        </div>
      </div>
    </>
  );
};
