import { Fragment, useEffect } from 'react';

import { getDiscountPrice, useGetEventRoom, useGetUser } from 'database';
import { Banner, Spacer } from 'design-system';
import { DEPOSIT } from 'shared-values';
import { useShallow } from 'zustand/react/shallow';

import { Loading } from '@components/Loading';
import { InfoPopover } from '@components/Popover';
import { useMergedLessonInfoOnTest } from '@components/ShortReservationFunnel/hooks/use-is-reservation-funnel-test';

import { usePageQuery } from '@hooks/use-handle-url';

import { usePurchaseData } from '../../Purchase.store';
import { SignupBenefit } from './components/SignupBenefit';

const CouponBoxes: { name: string; source: DiscountSource }[] = [
  {
    name: '운전선생 쿠폰',
    source: 'dt',
  },
  {
    name: '실내운전연습장 쿠폰',
    source: 'simulation',
  },
];

const CouponsBox = ({ 결제금 }: { 결제금: number }) => {
  const { selectedCoupons, academy } = usePurchaseData(
    useShallow((state) => ({
      selectedCoupons: state.selectedCoupons,
      academy: state.academy,
    })),
  );

  if (!selectedCoupons.length)
    return (
      <Fragment>
        <Spacer className="h-16" />
        <div className="flex w-full flex-row items-start justify-between">
          <div className="text-new-Body2-medium text-new-gray-500 py-2">운전선생 쿠폰</div>
          <div className="flex flex-col items-end">
            <div className="flex items-center gap-6">{0}원</div>
          </div>
        </div>
      </Fragment>
    );

  return (
    <>
      {CouponBoxes.filter((box) => {
        // academyInfo.type이 "academy"이면 "simulation" 소스를 가진 쿠폰은 제외
        return academy?.isSimulation || box.source !== 'simulation';
      }).map((couponBox, index) => {
        const { name, source } = couponBox;
        // selectedCoupons는 각 타입별로 하나씩만 존재함.
        const selectedCoupon = selectedCoupons.find(
          (coupon) => coupon.discount.discountSource === source && !coupon.isExpired,
        );

        if (!selectedCoupon) return;

        return (
          <Fragment key={`coupon-box-${index}`}>
            <Spacer className="h-16" />
            <div className="flex w-full flex-row items-start justify-between">
              <div className="text-new-Body2-medium text-new-gray-500 py-2">{name}</div>
              <div className="flex flex-col items-end">
                <div className="flex items-center gap-6">
                  -
                  {getDiscountPrice({
                    price: 결제금,
                    discounts: [selectedCoupon.discount],
                  }).할인금.toLocaleString()}
                  원
                </div>
                <Spacer className="h-2" />
                <div className="text-new-Caption1-medium text-new-gray-500">
                  {selectedCoupon.name}
                </div>
              </div>
            </div>
          </Fragment>
        );
      })}
    </>
  );
};

const EventRoomBox = ({ 결제금 }: { 결제금: number }) => {
  const { user } = useGetUser();
  const { data: eventRoom } = useGetEventRoom(user?.eventRoomId);

  if (!eventRoom) return <Loading />;

  const {
    eventAcademy: { togetherEventTable },
    readyUserCount,
  } = eventRoom;

  const discounts: Discount[] = [eventRoom.discount];
  const discountResult = getDiscountPrice({
    price: 결제금,
    discounts,
  });

  return (
    <>
      <Spacer className="h-16" />
      <div className="flex w-full flex-row items-start justify-between">
        <div className="text-new-Body2-medium text-new-gray-500 py-2">
          {togetherEventTable
            ? `동시 등록 할인(${readyUserCount}인)`
            : `같이 취득 할인(${readyUserCount}인)`}
        </div>
        <div>{discountResult.할인금 > 0 ? `-${discountResult.할인금.toLocaleString()}` : 0}원</div>
      </div>
    </>
  );
};

const useSetPrice = () => {
  const { academy, lessonInfo, selectedCoupons, setDiscountResult, setDiscounts, is전액결제 } =
    usePurchaseData(
      useShallow((state) => ({
        academy: state.academy,
        lessonInfo: state.lessonInfo,
        selectedCoupons: state.selectedCoupons,
        setDiscountResult: state.setDiscountResult,
        setDiscounts: state.setDiscounts,
        is전액결제: state.is전액결제,
      })),
    );

  const { user } = useGetUser();
  const { data: eventRoom } = useGetEventRoom(user?.eventRoomId);
  const { applicationMode } = usePageQuery<'/academy/[id]/application'>();

  useEffect(() => {
    if (!academy || !lessonInfo) return;

    const 결제금 = Number(is전액결제 ? lessonInfo.lessonPrice : DEPOSIT);

    const discounts: Discount[] = [];
    if (applicationMode === 'together-purchase' && eventRoom) {
      discounts.push(eventRoom.discount);
    } else {
      discounts.push(...selectedCoupons.map((coupon) => coupon.discount));
    }

    const discountResult = getDiscountPrice({
      price: 결제금,
      discounts,
    });
    setDiscountResult(discountResult);
    setDiscounts(discounts);
  }, [selectedCoupons, academy, lessonInfo, eventRoom, applicationMode, is전액결제]);
};

export const PriceBox = () => {
  const { applicationMode } = usePageQuery<'/academy/[id]/application'>();

  useSetPrice();

  const {
    academy,
    discountResult,
    lessonInfo: funnelLessonInfo,
    is전액결제,
  } = usePurchaseData(
    useShallow((state) => ({
      academy: state.academy,
      discountResult: state.discountResult,
      lessonInfo: state.lessonInfo,
      is전액결제: state.is전액결제,
    })),
  );

  const lessonInfo = useMergedLessonInfoOnTest({
    academyId: academy?.id,
    funnelLessonInfo,
  });

  if (!academy || !lessonInfo) return null;

  const {
    lessonPrice = 0,
    lessonAdditionalPrice = 0,
    lessonDiscountPrice = 0,
    lessonTotalPrice = 0,
  } = lessonInfo;

  const { 원금, 최종금 } = discountResult || { 원금: 0, 최종금: 0 };

  const 결제금 = Number(is전액결제 ? lessonPrice : DEPOSIT);

  return (
    <section>
      <SignupBenefit />
      <div className="flex flex-col">
        <p className="text-new-Sub-Title text-new-gray-900">결제 금액</p>
        <Spacer className="h-16" />
        <div className="flex w-full flex-row items-center justify-between">
          <div className="flex gap-2">
            <p className="text-new-Body2-medium text-new-gray-500">{`${
              !is전액결제 ? '선결제금' : `결제 금액`
            }`}</p>
            {!is전액결제 && (
              <InfoPopover
                content="해당 학원 예약 시 운전선생을 통해 먼저 결제하는 금액입니다."
                side="top"
                align="start"
                alignOffset={-50}
              />
            )}
          </div>
          <span className="text-new-Body1-medium">
            {(!is전액결제 ? 원금 : lessonTotalPrice - lessonAdditionalPrice).toLocaleString()}원
          </span>
        </div>
        {lessonDiscountPrice > 0 && (
          <>
            <div className="flex w-full flex-row items-center justify-between">
              <p className="text-new-Body2-medium text-new-gray-500">운전학원 전용 할인</p>
              <span className="text-new-Body1-medium">
                -{lessonDiscountPrice.toLocaleString()}원
              </span>
            </div>
            <Spacer className="h-16" />
          </>
        )}
        {applicationMode === 'together-purchase' ? (
          <EventRoomBox 결제금={결제금} />
        ) : (
          <CouponsBox 결제금={결제금} />
        )}
        <Spacer className="h-16" />
        <hr className="border-new-gray-100 -mx-16 border" />
        <Spacer className="h-16" />
        <div className="flex w-full flex-row items-center justify-between">
          <div className="text-new-Body2-bold text-new-gray-900">
            {!is전액결제 || lessonAdditionalPrice > 0 ? '지금 결제할 금액' : '최종 결제금'}
          </div>
          <div className="text-new-Body1-bold text-new-DTRed-400">
            = {최종금.toLocaleString()}원
          </div>
        </div>
      </div>

      {lessonAdditionalPrice > 0 && (
        <>
          <Spacer className="h-[16px]" />
          <section className="border-1 border-new-DTPurple-500 flex justify-between rounded-[8px] p-16">
            <p className={'text-new-Body2-bold text-new-gray-900'}>학원 방문 후 추가 결제금</p>
            <div className="flex flex-col items-end gap-[1.5px]">
              <p className="text-new-Caption1-medium text-new-gray-500">검정료 (시험 응시 비용)</p>
              <p className="text-new-Body1-bold text-new-gray-900">
                = {lessonAdditionalPrice.toLocaleString()}원
              </p>
            </div>
          </section>
        </>
      )}
      {!is전액결제 && (
        <>
          <Spacer className="h-[16px]" />
          <section className="border-1 border-new-DTPurple-500 flex justify-between rounded-[8px] p-16">
            <p className={'text-new-Body2-bold text-new-gray-900'}>학원 방문 후 추가 결제금</p>
            <div className="flex flex-col items-end gap-[1.5px]">
              <p className="text-new-Caption1-medium text-new-gray-500">
                {lessonPrice.toLocaleString()} - {DEPOSIT.toLocaleString()}
              </p>
              <p className="text-new-Body1-bold text-new-gray-900">
                = {(lessonPrice - DEPOSIT).toLocaleString()}원
              </p>
            </div>
          </section>
        </>
      )}
      {!academy.isSimulation && (
        <>
          <Spacer className="h-16" />
          <Banner
            description={
              lessonAdditionalPrice > 0
                ? '해당 학원은 검정료를 현금으로만 받으며, 방문 후 결제해야 합니다. 추가 비용(보험비·교재비 등)이 발생할 수 있습니다.'
                : '보험비나 교재비 등 추가 비용이 발생할 수 있습니다.'
            }
            size="small"
            type="content-left"
            icon="info-outlined"
          />
        </>
      )}
    </section>
  );
};
