import React, { useState } from 'react';

import { useGetUser } from 'database';
import {
  NewBottomSheet,
  NewBottomSheetContent,
  NewBottomSheetHeader,
  NewBottomSheetTitle,
  NewBottomSheetTrigger,
  NewBoxButton,
  Spacer,
  useToast,
} from 'design-system';
import { useRouter } from 'next/router';

import { CouponCard } from '@components';
import { NeedLoginBottomSheet } from '@components/NeedLoginBottomSheet/NeedLoginBottomSheet';

import { ERROR_MESSAGE } from '@constants';

interface CouponBottomSheetProps {
  downloadable?: boolean;
  coupons: CouponCode[];
  onClickReceiveCoupon?: (coupon: CouponCode | IssuedCoupon) => void;
  onClickReceiveAllCoupons?: (coupons?: CouponCode[]) => void;
  children: React.ReactNode;
}

const addAllCoupons = async (user: User, coupons: CouponCode[]) => {
  await Promise.all(
    coupons.map(async (coupon) => {
      if (user.isAlreadyParticipatedCouponEvent(coupon.source)) {
        return null;
      }

      await user.addCoupon({ couponCode: coupon.get() });
    }),
  );
};

export const CouponBottomSheet = ({
  downloadable = true,
  coupons = [],
  onClickReceiveCoupon,
  onClickReceiveAllCoupons,
  children,
}: CouponBottomSheetProps) => {
  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);

  const { user } = useGetUser();

  const isAllAlreadyParticipatedCouponEvent = coupons.every(
    (coupon) => user && user.isAlreadyParticipatedCouponEvent(coupon.source),
  );

  const { openToast } = useToast();

  const redirectToLogin = () => {
    router.push(`/login?redirectUrl=${router.asPath}`);
    close();
  };

  return (
    <NewBottomSheet open={isOpen} onOpenChange={setIsOpen}>
      <NewBottomSheetTrigger asChild>{children}</NewBottomSheetTrigger>
      <NewBottomSheetContent className="max-h-[500px]">
        <NewBottomSheetHeader>
          <NewBottomSheetTitle>받을 수 있는 쿠폰</NewBottomSheetTitle>
          <div className="scrollbar-hide flex max-h-[230px] flex-col gap-8 overflow-auto">
            {coupons.map((coupon) => {
              return (
                <CouponCard
                  coupon={coupon}
                  key={coupon.source}
                  onClickDownloadCoupon={onClickReceiveCoupon}
                  isDisabled={user?.isAlreadyParticipatedCouponEvent(coupon.source)}
                  downloadable={downloadable}
                  onFail={redirectToLogin}
                />
              );
            })}
          </div>
          <Spacer className="h-[24px]" />
          <NeedLoginBottomSheet>
            <NewBoxButton
              label={isAllAlreadyParticipatedCouponEvent ? '모든 쿠폰 받기 완료' : '모든 쿠폰 받기'}
              fill
              disabled={isAllAlreadyParticipatedCouponEvent}
              onClick={async () => {
                if (!user) return;

                try {
                  await addAllCoupons(user, coupons);

                  openToast({ title: '모든 쿠폰이 발급되었습니다.', yPosition: 50 });
                } catch (error) {
                  if (error instanceof Error) {
                    alert(error.message);
                  } else {
                    alert(ERROR_MESSAGE.UNDEFINED);
                  }
                }

                onClickReceiveAllCoupons && onClickReceiveAllCoupons(coupons);
                close();
              }}
            />
          </NeedLoginBottomSheet>
        </NewBottomSheetHeader>
      </NewBottomSheetContent>
    </NewBottomSheet>
  );
};
