import { useMemo } from 'react';

import { useGetUser } from '@db/collections';
import { BottomSheet, NewIcon, Spacer, useOverlay } from 'design-system';
import { cn } from 'tailwind-config';
import { useShallow } from 'zustand/react/shallow';

import { useMergedLessonInfoOnTest } from '@components/ShortReservationFunnel/hooks/use-is-reservation-funnel-test';
import { useGetNonMemberFlag } from '@pages/academy/[id]/application';
import { PrivateTerm } from '@templates/Terms/PrivateTerm';

import { usePurchaseData } from '../../Purchase.store';
import { PrivateInfoProvideTerm } from './components';

const CheckList = [
  {
    type: 'info',
    text: '[필수] 개인정보 제 3자 제공',
  },
  {
    type: 'age',
    text: '[필수] 만 14세 이상입니다.',
  },
  {
    type: 'private',
    text: '[필수] 개인정보 수집 및 이용',
  },
  {
    type: 'refund',
    text: '[필수] 취소 및 환불 규정 동의',
  },
] as const;

const getBottomSheetContent = (type: 'info' | 'private' | 'refund' | null, academyName: string) => {
  switch (type) {
    case 'info':
      return <PrivateInfoProvideTerm academyName={academyName} />;
    case 'private':
      return (
        <div className="h-[500px]">
          <PrivateTerm />
        </div>
      );
    default:
      break;
  }
};

export const useGetPurchaseAgreement = () => {
  const { user } = useGetUser();
  const purchaseAgreementData = usePurchaseData(
    useShallow((state) => ({
      purchaseAgreement: state.purchaseAgreement,
      setPurchaseAgreement: state.setPurchaseAgreement,
    })),
  );

  const isAllChecked = useMemo(
    () =>
      purchaseAgreementData.purchaseAgreement.info &&
      purchaseAgreementData.purchaseAgreement.private &&
      purchaseAgreementData.purchaseAgreement.refund &&
      (!user || user.provider === 'non-member'
        ? purchaseAgreementData.purchaseAgreement.age
        : true),
    [purchaseAgreementData, user],
  );

  return { ...purchaseAgreementData, isAllChecked };
};

export const PurchaseAgreement = () => {
  const overlay = useOverlay();

  const { academy, lessonInfo: funnelLessonInfo } = usePurchaseData(
    useShallow((state) => ({
      academy: state.academy,
      lessonInfo: state.lessonInfo,
      purchaseAgreement: state.purchaseAgreement,
      setPurchaseAgreement: state.setPurchaseAgreement,
    })),
  );

  const lessonInfo = useMergedLessonInfoOnTest({
    academyId: academy?.id,
    funnelLessonInfo,
  });

  const { user } = useGetUser();

  const { purchaseAgreement, setPurchaseAgreement, isAllChecked } = useGetPurchaseAgreement();

  const flag = useGetNonMemberFlag();

  if (!academy || !lessonInfo) return null;

  const { name: academyName } = academy;

  const handleTermsBottomSheetClick = (type: 'info' | 'private' | 'refund' | 'age' | null) => {
    if (!type || type === 'refund' || type === 'age') return null;

    overlay.open(({ isOpen, close }) => {
      return (
        <BottomSheet
          isOpen={isOpen}
          onClose={() => {
            close();
          }}
          header={{
            title:
              type === 'info'
                ? '개인정보 제3자 제공'
                : type === 'private'
                ? '개인정보처리방침'
                : '',
          }}
          main={
            <article className="h-[520px] overflow-y-auto">
              {getBottomSheetContent(type, academyName)}
            </article>
          }
        />
      );
    });
  };

  return (
    <>
      <h4 className="text-new-Sub-Title text-new-gray-900">
        <span className="text-new-DTRed-400 text-new-Sub-Title">*</span>결제 동의
      </h4>
      <Spacer className="h-20" />
      <div
        className="flex cursor-pointer flex-row items-center gap-8"
        onClick={() => {
          if (isAllChecked) {
            setPurchaseAgreement({
              info: false,
              age: false,
              private: false,
              refund: false,
            });
          } else {
            setPurchaseAgreement({
              info: true,
              private: true,
              refund: true,
              age: true,
            });
          }
        }}
      >
        <NewIcon
          icon="checkincircle-filled"
          size={24}
          className={cn(isAllChecked ? 'fill-new-DTPurple-500' : 'fill-new-gray-400')}
        />
        <p className="text-new-Body1-bold text-new-gray-900">약관 전체 동의</p>
      </div>
      <div className="flex flex-col gap-12 px-32 pt-12">
        {CheckList.filter((check) =>
          flag !== 'test' || (user && user.provider !== 'non-member') ? check.type !== 'age' : true,
        ).map((check, index) => {
          const { type, text } = check;
          const isChecked = purchaseAgreement[type];
          return (
            <div
              key={index}
              className="flex w-fit cursor-pointer flex-row items-center gap-8"
              onClick={() => {
                const next = { ...purchaseAgreement };
                next[type] = !next[type];
                setPurchaseAgreement(next);
              }}
            >
              <NewIcon
                icon="checkincircle-filled"
                size={24}
                className={cn(isChecked ? 'fill-new-DTPurple-500' : 'fill-new-gray-400')}
              />
              <div
                className="text-new-Body2-medium"
                onClick={() => {
                  handleTermsBottomSheetClick(type);
                }}
              >
                {text}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
