import { Banner, Spacer } from 'design-system';
import { cn } from 'tailwind-config';
import { useShallow } from 'zustand/react/shallow';

import { useMergedLessonInfoOnTest } from '@components/ShortReservationFunnel/hooks/use-is-reservation-funnel-test';

import { usePurchaseData } from '../Purchase.store';

interface ShuttleBusPreferenceListType {
  type: ShuttleBusPreference;
  text: string;
}

const shuttleBusPreferenceList: ShuttleBusPreferenceListType[] = [
  {
    type: 'yes',
    text: '셔틀 탑승',
  },
  {
    type: 'no',
    text: '탑승 안함',
  },
  {
    type: 'unsure',
    text: '잘 모름',
  },
];

export const ShuttleBusPreference = () => {
  const {
    shuttleBusPreference,
    setShuttleBusPreference,
    academy,
    lessonInfo: funnelLessonInfo,
  } = usePurchaseData(
    useShallow((state) => ({
      shuttleBusPreference: state.shuttleBusPreference,
      setShuttleBusPreference: state.setShuttleBusPreference,
      academy: state.academy,
      lessonInfo: state.lessonInfo,
    })),
  );

  const lessonInfo = useMergedLessonInfoOnTest({
    academyId: academy?.id,
    funnelLessonInfo,
  });

  const is면허캠프 = lessonInfo?.lessonCode === 'TWO_AUTO_CAMP';

  if (academy?.isSimulation || academy?.isSpecificAcademy('창동') || is면허캠프 || !lessonInfo)
    return null;

  return (
    <>
      <h4 className="text-new-Sub-Title text-new-gray-900">셔틀버스 탑승 여부</h4>
      <Spacer className="h-16" />
      <ul className="flex gap-8">
        {shuttleBusPreferenceList.map((el) => (
          <button
            key={el.type}
            onClick={() => setShuttleBusPreference(el.type)}
            className={cn(
              'flex w-full cursor-pointer items-center justify-center gap-6 rounded-[8px] py-8 transition-colors duration-100',
              el.type === shuttleBusPreference
                ? 'bg-new-DTPurple-500 text-new-Body2-bold text-white'
                : 'border-1 border-new-gray-200 text-new-Body2-medium text-new-gray-600',
            )}
          >
            {el.text}
          </button>
        ))}
      </ul>
      <Spacer className="h-16" />
      <Banner
        description="셔틀버스 탑승을 위해 학원에 연락이 올 수 있습니다."
        size="small"
        type="content-left"
        icon="info-outlined"
      />
    </>
  );
};
