import { Spacer } from 'design-system';
import { useShallow } from 'zustand/react/shallow';

import { usePurchaseData } from '../../../Purchase.store';

export const RefundPolicyBanner = () => {
  const { academy, is전액결제 } = usePurchaseData(
    useShallow((state) => ({
      academy: state.academy,
      is전액결제: state.is전액결제,
    })),
  );

  return (
    <>
      <div className="bg-new-gray-50 w-full rounded-[16px] p-20">
        <div className="text-new-Body1-bold text-new-gray-900">환불 정책</div>
        <Spacer className="h-8" />
        <ul className="text-new-Body2-medium">
          <li>
            <span className="text-new-gray-600">· 1일(24시간) 전 ~ 도착, 노쇼 : </span>
            <span className="text-new-DTRed-400">
              {is전액결제 ? '환불수수료 5만원' : '환불 불가'}
            </span>
          </li>
          <li>
            <span className="text-new-gray-600">· 2일(48시간) 전 ~ 1일 : </span>
            <span className="text-new-DTRed-400">
              {is전액결제 ? '환불수수료 2.5만원' : '50% 환불'}
            </span>
          </li>
          <li>
            <span className="text-new-gray-600">· 예약 신청 날 ~ 2일 : </span>
            <span className="text-new-DTRed-400">100% 환불</span>
          </li>
        </ul>
        <span className="text-new-Caption1-medium text-new-gray-500">
          * 노쇼(No-Show) : 예약한 시간에 방문하지 않는 행위를 뜻합니다.
        </span>
      </div>
    </>
  );
};
