import { LogEvent } from '@analytics/LogEvent/LogEvent';
import { useGetUser } from '@db/collections';
import { NewBoxButton, useToast } from 'design-system';
import { useShallow } from 'zustand/react/shallow';

import { NeedLoginBottomSheet } from '@components/NeedLoginBottomSheet/NeedLoginBottomSheet';
import {
  useMergedLessonInfoOnTest,
  useMergedSelectedTimeOrRangeOnTest,
} from '@components/ShortReservationFunnel/hooks/use-is-reservation-funnel-test';
import { useGetNonMemberFlag } from '@pages/academy/[id]/application';

import { usePageQuery } from '@hooks/use-handle-url';
import { getDeviceByUserAgent } from '@utils/Common';

import { RequiredKey } from '../../Purchase';
import { usePurchaseData } from '../../Purchase.store';
import { useGetPurchaseAgreement } from '../PurchaseAgreement';
import { PurchaseButton } from './PurchaseButton';

const LessonInfo = () => {
  const {
    discountResult,
    lessonInfo: funnelLessonInfo,
    academy,
  } = usePurchaseData(
    useShallow((state) => ({
      discountResult: state.discountResult,
      lessonInfo: state.lessonInfo,
      academy: state.academy,
    })),
  );

  const lessonInfo = useMergedLessonInfoOnTest({
    funnelLessonInfo,
    academyId: academy?.id,
  });

  const { lessonName, lessonDiscountPrice } = lessonInfo;

  const totalDiscountPrice = discountResult.할인금 + lessonDiscountPrice;

  return (
    <>
      <div className="w-full pt-5">
        <div className="text-Body4 text-new-gray-500 leading-[22.4px]">{lessonName}</div>
        <div>
          {totalDiscountPrice > 0 ? (
            <div className="flex items-center gap-4">
              <div className="text-Body1 text-new-DTRed-400 leading-[25.6px]">
                {(discountResult.원금 + lessonDiscountPrice - totalDiscountPrice).toLocaleString()}
                원
              </div>
              <span className="text-[13px] text-gray-400 line-through	">
                {(discountResult.원금 + lessonDiscountPrice).toLocaleString()}원
              </span>
            </div>
          ) : (
            <div className="text-Body1 leading-[25.6px]">
              {discountResult.최종금.toLocaleString()}원
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export type PurchaseCondition = Record<
  RequiredKey,
  { value: boolean; message: string; component: HTMLDivElement | null }
>;

const RequiredCondition = ({
  children,
  purchaseCondition,
}: {
  children: React.ReactNode;
  purchaseCondition: PurchaseCondition;
}) => {
  const { openToast } = useToast();

  return (
    <div
      onClick={(e) => {
        const findCondition = Object.values(purchaseCondition).find(
          (condition) => !condition.value,
        );

        if (findCondition) {
          findCondition.component?.scrollIntoView({ behavior: 'smooth' });

          openToast({
            title: findCondition.message,
          });
          e.preventDefault();
          return;
        }
      }}
    >
      {children}
    </div>
  );
};

export const PurchaseBottom = ({
  requiredComponents,
  userAddress,
  overrideApplicationMode,
  overrideReceipt,
  visitTrainingReceipt,
}: {
  requiredComponents: Record<RequiredKey, HTMLDivElement | null>;
  userAddress?: string;
  overrideApplicationMode?: string;
  overrideReceipt?: Partial<ReceiptData>;
  visitTrainingReceipt?: VisitTrainingReceiptData;
}) => {
  const { isAllChecked: isPurchaseAgreementChecked } = useGetPurchaseAgreement();

  const { user } = useGetUser();
  const {
    discounts,
    discountResult,
    lessonInfo: funnelLessonInfo,
    academy,
    visitorInfo,
    shuttleBusPreference,
    selectedTime: funnelSelectedTime,
    selectedRange: funnelSelectedRange,
    selectedCoupons,
    paymentMethod,
    is전액결제,
  } = usePurchaseData(
    useShallow((state) => ({
      discounts: state.discounts,
      academy: state.academy,
      discountResult: state.discountResult,
      lessonInfo: state.lessonInfo,
      visitorInfo: state.visitorInfo,
      shuttleBusPreference: state.shuttleBusPreference,
      selectedTime: state.selectedTime,
      selectedRange: state.selectedRange,
      selectedCoupons: state.selectedCoupons,
      paymentMethod: state.paymentMethod,
      is전액결제: state.is전액결제,
    })),
  );

  const purchaseCondition: PurchaseCondition = {
    userInfo: {
      value: !!user,
      message: '예약자 정보가 필요해요.',
      component: requiredComponents.userInfo,
    },
    visitorInfo: {
      value: visitorInfo.name.trim().length > 0 && visitorInfo.phoneNum.trim().length > 0,
      message: '수강생 정보가 필요해요.',
      component: requiredComponents.visitorInfo,
    },
    agreement: {
      value: isPurchaseAgreementChecked,
      message: '결제 동의가 필요해요.',
      component: requiredComponents.agreement,
    },
  };

  const { applicationMode } = usePageQuery<'/academy/[id]/application'>();

  // const flag = useFeatureFlagVariantKey('non-member');
  const flag = useGetNonMemberFlag();

  // 예약 퍼널 줄이기 AB 테스트 관련
  const lessonInfo = useMergedLessonInfoOnTest({
    academyId: academy?.id,
    funnelLessonInfo,
  });
  const { selectedTime, selectedRange } = useMergedSelectedTimeOrRangeOnTest({
    funnelSelectedTime,
    funnelSelectedRange,
    academyId: academy?.id,
  });
  // 예약 퍼널 줄이기 AB 테스트 관련 끝

  if (!academy || !lessonInfo) return null;

  const {
    id: academyId,
    location: { bubAddress: academyLocation },
    name: academyName,
    phoneNum: academyPhoneNum,
    submallInfo: { subCoNo: academySubCoNo, subMid: academySubMid } = { subCoNo: '', subMid: '' },
    smallmallInfo: { smbsRank: academySmallRank } = { smbsRank: '' },
    paymentType,
    isSimulation,
    images,
  } = academy;

  const receipt: Omit<ReceiptData, 'id'> = {
    academyId,
    academySubCoNo,
    academySubMid,
    academySmallRank,
    academyLocation: academyLocation ?? '',
    academyName,
    academyPhoneNum: academyPhoneNum ?? '',
    image: images[0],
    deposit: discountResult ? discountResult.최종금 : 0,
    selectedTimes: selectedTime ? [selectedTime.get()] : [],
    selectedRange: selectedRange ? selectedRange.get() : null,
    userId: user?.uid ?? '',
    userName: user?.realName ?? user?.name ?? '',
    userPhoneNum: user?.phoneNum ?? '',
    userBirthday: user?.birthday ?? '',
    userAddress: userAddress ?? (user?.address ? user.address : ''),
    userDetailAddress: user?.detailAddress ? user.detailAddress : '',
    visitorName: visitorInfo.name,
    visitorBirthday: visitorInfo.birthday,
    visitorPhoneNum: visitorInfo.phoneNum.replaceAll('-', ''),
    shuttleBusPreference: shuttleBusPreference,
    device: getDeviceByUserAgent(),
    paymentType: is전액결제 ? 'entire' : paymentType,
    isSimulation,
    applicationMode: overrideApplicationMode ?? applicationMode,
    userType: user?.provider === 'non-member' ? 'non-member' : 'member',
    ...lessonInfo,
    ...overrideReceipt,
  };

  return (
    <>
      <LessonInfo />
      {flag !== 'test' && !user ? (
        <NeedLoginBottomSheet
          title="예약을 위해 추가 정보가 필요해요."
          description="지금까지 입력한 정보는 그대로 유지돼요."
        >
          <NewBoxButton label="결제하기" styles="filled-yellow" className="!w-[171px]" />
        </NeedLoginBottomSheet>
      ) : (
        <RequiredCondition purchaseCondition={purchaseCondition}>
          <PurchaseButton
            receipt={receipt}
            addressString={user?.address ? user.address + user.detailAddress : ''}
            isTogetherEvent={applicationMode === 'together-purchase'}
            isKeyInPurchase={paymentMethod === 'keyin'}
            discountPrice={discountResult ? discountResult?.할인금 : 0}
            totalPrice={discountResult ? discountResult.최종금 : 0}
            운전선생할인금={discountResult ? discountResult.운전선생할인금 : 0}
            외부할인금={discountResult ? discountResult.외부할인금 : 0}
            onClickPurchase={(purchaseMethod) => {
              const lesson = academy.selectLesson({
                code: lessonInfo.lessonCode,
                periodText: lessonInfo.lessonPeriodText,
                registType: lessonInfo.lessonRegistType as RegistType,
              });

              LogEvent.매출.$결제시작(academy, lesson, applicationMode, purchaseMethod, discounts);
            }}
            discounts={discounts}
            selectedCoupons={selectedCoupons}
            styles={'filled-yellow'}
            visitTrainingReceipt={visitTrainingReceipt}
          />
        </RequiredCondition>
      )}
    </>
  );
};
