import { useEffect, useMemo, useRef } from 'react';

import { useGetAcademyCouponCodes, useGetFirstComeCouponCode, useGetUser } from '@db/collections';
import { Spacer } from 'design-system';
import { useRouter } from 'next/router';
import { useShallow } from 'zustand/react/shallow';

import { useMergedLessonInfoOnTest } from '@components/ShortReservationFunnel/hooks/use-is-reservation-funnel-test';
import { isGlobalFromOnsiteEvent, isGlobalLinkFromAcademy } from '@pages/academy/[id]/application';

import { usePageQuery } from '@hooks/use-handle-url';

import { usePurchaseData } from '../../Purchase.store';
import { CouponButton } from './components';
import { selectBestCoupons } from './utils/select-best-coupons';

export const 선착순쿠폰학원홈페이지허용학원 = ['mdoxbMRnxWMSOv9TOK4Q'];

const CouponBoxes: { name: string; source: DiscountSource }[] = [
  {
    name: '운전선생 쿠폰',
    source: 'dt',
  },
  {
    name: '실내운전연습장 쿠폰',
    source: 'simulation',
  },
];

const useInitializeBestCoupons = ({ coupons }: { coupons: CouponCode[] }) => {
  const { user } = useGetUser();
  const {
    setSelectedCoupons,
    lessonInfo: funnelLessonInfo,
    academy,
  } = usePurchaseData(
    useShallow((state) => ({
      setSelectedCoupons: state.setSelectedCoupons,
      lessonInfo: state.lessonInfo,
      academy: state.academy,
    })),
  );

  const lessonInfo = useMergedLessonInfoOnTest({
    academyId: academy?.id,
    funnelLessonInfo,
  });

  const initialized = useRef(false);

  /**
   * initial selected coupons
   */
  useEffect(() => {
    if (
      !user ||
      user.provider === 'non-member' ||
      initialized.current ||
      !coupons.length ||
      !lessonInfo
    )
      return;

    initialized.current = true;
    const lessonPrice = lessonInfo.lessonPrice;

    const newSelectedCoupons = selectBestCoupons(coupons, lessonPrice);
    /**
     * 241212 (노크쿠폰을 보유한 경우) & (선착순 쿠폰이 1만원이 넘어가는 경우) => 중복 적용해야 하는 요구가 있었음.
     */
    const knockCoupon = coupons.find((coupon) => coupon.source === 'KNOCK-PRIVATE');
    const firstComeCoupon = newSelectedCoupons.find((coupon) => coupon.source === 'first-come');

    setSelectedCoupons([
      ...newSelectedCoupons,
      ...(firstComeCoupon && firstComeCoupon.discount.discountPrice > 10000 && knockCoupon
        ? [knockCoupon]
        : []),
    ]);
  }, [coupons, lessonInfo]);
};

export const Coupons = () => {
  const { user } = useGetUser();
  const { lessonInfo, selectedCoupons, setSelectedCoupons, academy, discountResult } =
    usePurchaseData(
      useShallow((state) => ({
        lessonInfo: state.lessonInfo,
        selectedCoupons: state.selectedCoupons,
        setSelectedCoupons: state.setSelectedCoupons,
        academy: state.academy,
        discountResult: state.discountResult,
      })),
    );

  const { applicationMode } = usePageQuery<'/academy/[id]/application'>();

  const { data: firstCoupon } = useGetFirstComeCouponCode(academy?.id, false);
  const router = useRouter();

  const academyId = academy ? academy.id : '';
  const { forceUseFirstCome } = router.query;

  const discountCondition: DiscountCondition | null = useMemo(
    () =>
      lessonInfo
        ? {
            periodText: lessonInfo.lessonPeriodText,
            registType: lessonInfo.lessonRegistType,
            lessonCode: lessonInfo.lessonCode,
            academyId: academyId as string,
          }
        : null,
    [lessonInfo, academyId],
  );

  const { data = [] } = useGetAcademyCouponCodes({
    academyId: academyId as string,
    condition: discountCondition,
  });

  const coupons = useMemo(
    () =>
      discountCondition
        ? [
            ...(firstCoupon &&
            (Boolean(forceUseFirstCome) ||
              (firstCoupon.canUse &&
                (선착순쿠폰학원홈페이지허용학원.includes(academyId) ||
                  !isGlobalLinkFromAcademy))) &&
            firstCoupon.discount.canApply(discountCondition) &&
            !isGlobalFromOnsiteEvent
              ? [firstCoupon]
              : []),
            ...(user
              ? user
                  .getApplicableCoupons(discountCondition)
                  .filter((coupon) => coupon.isValid)
                  .map((coupon) => coupon.toCouponCode())
              : []),
          ]
        : [],

    [academyId, firstCoupon, user?.coupons, discountCondition],
  );

  useInitializeBestCoupons({ coupons: coupons });

  if (
    !user ||
    user.provider === 'non-member' ||
    !academy ||
    !lessonInfo ||
    applicationMode === 'together-purchase'
  )
    return;

  return (
    <div>
      <div className="flex items-center justify-between">
        <h2 className="text-new-Sub-Title">할인 쿠폰</h2>
        <p className="text-new-Body2-medium text-new-gray-500">{coupons.length}장 보유</p>
      </div>
      <Spacer className="h-4" />
      <p className="text-new-Body2-medium text-new-gray-500">
        운전선생에서 자체 발행하는 할인 쿠폰입니다.
      </p>
      <Spacer className="h-16" />
      <div className="flex flex-col gap-16">
        {CouponBoxes.filter((box) => {
          // academyInfo.type이 "academy"이면 "simulation" 소스를 가진 쿠폰은 제외
          return academy.isSimulation || box.source !== 'simulation';
        }).map((couponBox, index) => {
          const { name, source } = couponBox;
          // selectedCoupons는 각 타입별로 하나씩만 존재함.
          const selectedCoupon = selectedCoupons.find(
            (coupon) => coupon.discount.discountSource === source && !coupon.isExpired,
          );

          const selectableCoupons = coupons.filter(
            (coupon) => coupon.discount.discountSource === source && !coupon.isExpired,
          );
          const receivableCoupons = academy.isSimulation
            ? data.filter(
                (coupon) => coupon.discount.discountSource === source && !coupon.isExpired,
              )
            : [];

          return (
            <div
              className="flex w-full flex-row items-start justify-between"
              key={`coupon-box-${index}`}
            >
              <div className="text-new-Body2-medium text-new-gray-500 py-2">{name}</div>
              <div className="flex flex-col items-end">
                <CouponButton
                  결제금={discountResult.원금}
                  selectableCoupons={selectableCoupons}
                  receivableCoupons={receivableCoupons}
                  selectedCoupon={selectedCoupon}
                  onSelectCoupon={(coupon) => {
                    const excludeCoupons = selectedCoupons.filter(
                      (coupon) => coupon.discount.discountSource !== source,
                    );
                    setSelectedCoupons([...excludeCoupons, coupon]);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
