import { useEffect } from 'react';

import { useGetAcademy } from '@db/collections';
import { useRouter } from 'next/router';
import { StateCreator, create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useShallow } from 'zustand/react/shallow';

import { queryStringStorage } from '@config';

import { useFunnelStore } from '@hooks/use-funnel';

import {
  DateSelectStore,
  LessonsSelectStore,
  SelectRegistTypeStore,
  dateSelectStore,
  lessonsSelectStore,
  selectRegistTypeStore,
} from '../screens';

/**
 * 각 화면 별로 필요한 데이터와 결정되는 데이터를 정의
 */
const RequiredData = {
  RegistTypeSelect: { requiredDataKeys: [] },
  LessonsSelect: { requiredDataKeys: ['selectedRegistType'] },
  DateSelect: {
    requiredDataKeys: ['selectedRegistType', 'lessonInfo'],
  },
  RangeSelect: {
    requiredDataKeys: ['selectedRegistType', 'lessonInfo'],
  },
  Purchase: { requiredDataKeys: ['selectedRegistType', 'lessonInfo'] },
  '': { requiredDataKeys: [] },
} as const;

/**
 * RequiredData에 따라 nullable인 데이터와 non-nullable인 데이터를 구분해주는 utility type
 * TODO : 해당 화면에 필요한 데이터가 없을 경우 이전 화면으로 도로 보내는 기능 추가하기.
 */
type RequiredDataForScreen<S extends keyof typeof RequiredData | ''> = {
  [K in keyof ApplicationData]: K extends (typeof RequiredData)[S]['requiredDataKeys'][number]
    ? NonNullable<ApplicationData[K]>
    : ApplicationData[K];
};

export type ApplicationData = DateSelectStore &
  LessonsSelectStore &
  SelectRegistTypeStore &
  CommonStore &
  VisitorStore;

const persistKey: (keyof ApplicationData)[] = [
  'selectedRange',
  'selectedTime',
  'lessonInfo',
  'selectedRegistType',
  'isFromTeamEvent',
];

export interface CommonStore {
  isFromTeamEvent: boolean;
  setIsFromTeamEvent: (isFromTeamEvent: boolean) => void;
}

export const commonStore: StateCreator<ApplicationData, [], [], CommonStore> = (set, get) => ({
  isFromTeamEvent: false,
  setIsFromTeamEvent: (isFromTeamEvent: boolean) => set({ isFromTeamEvent }),
});

export interface VisitorStore {
  visitorInfo: VisitorData;
  setVisitorInfo: (visitorInfo: VisitorData) => void;
  shuttleBusPreference: ShuttleBusPreference;
  setShuttleBusPreference: (shuttleBusPreference: ShuttleBusPreference) => void;
}

export const visitorStore: StateCreator<ApplicationData, [], [], VisitorStore> = (set) => ({
  visitorInfo: { name: '', birthday: '', phoneNum: '' },
  setVisitorInfo: (visitorInfo: VisitorData) => set({ visitorInfo }),
  shuttleBusPreference: 'yes',
  setShuttleBusPreference: (shuttleBusPreference: ShuttleBusPreference) =>
    set({ shuttleBusPreference }),
});

export const useApplicationData = create<ApplicationData>()(
  persist(
    (...a) => ({
      ...commonStore(...a),
      ...dateSelectStore(...a),
      ...lessonsSelectStore(...a),
      ...selectRegistTypeStore(...a),
      ...visitorStore(...a),
    }),
    queryStringStorage(persistKey),
  ),
);

/**
 * URL에 있는 Lesson 정보가 위조되었을 경우, 서버에서 받은 정보로 덮어씌워주는 hook
 */
export const useSyncLessonInfoOnServer = () => {
  const router = useRouter();
  const { id } = router.query;
  const { data: academy } = useGetAcademy(id as string);
  const applicationData = useApplicationData(
    useShallow((s) => ({
      lessonInfo: s.lessonInfo,
      setLessonInfo: s.setLessonInfo,
    })),
  );

  useEffect(() => {
    if (!academy) return;

    const { lessonInfo } = applicationData;

    if (!lessonInfo) return;

    const { lessonCode, lessonPeriodText = '', lessonRegistType = '' } = lessonInfo;

    const foundLesson = academy.lessons.find(
      (lesson) =>
        (lessonPeriodText.length > 0
          ? (lesson as SimulationLessonData)?.period?.text === lessonPeriodText
          : true) &&
        (lessonRegistType.length > 0
          ? (lesson as SimulationLessonData)?.registType === lessonRegistType
          : true) &&
        lesson.code === lessonCode,
    );

    if (!foundLesson) return;

    applicationData.setLessonInfo({
      ...lessonInfo,
      ...foundLesson.lessonInfo,
    });
  }, [academy]);
};

// S에는 현재 screen 값을 넣음. 초기 값은 ''로 설정
export const useApplicationFunnel = <S extends ApplicationScreen | '' = ''>() => {
  // client 데이터
  const applicationData = useApplicationData() as RequiredDataForScreen<S>;

  // funnel 데이터
  const funnelData = useFunnelStore();
  useEffect(() => {
    funnelData.connectDataStore({
      zustandStore: useApplicationData,
      persistKey: persistKey,
    });
  }, []);

  // server 데이터
  const router = useRouter();
  const { id } = router.query;
  const result = useGetAcademy(id as string);

  const academy = result.data as S extends '' ? Academy | null : Academy;

  return {
    ...applicationData,
    ...funnelData,
    isLoading: result.isLoading,
    academy,
  };
};
