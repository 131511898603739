import { Spacer } from 'design-system';
import { useShallow } from 'zustand/react/shallow';

import { usePurchaseData } from '../Purchase.store';

const PAYMENT_METHOD_OPTIONS = [
  {
    name: '카카오페이 / 네이버페이 / 페이코 / 앱 카드',
    type: 'card',
  },
  {
    name: '신용 카드',
    type: 'keyin',
  },
] as const;

export const Payment = () => {
  const { paymentMethod, setPaymentMethod } = usePurchaseData(
    useShallow(({ paymentMethod, setPaymentMethod }) => ({
      paymentMethod,
      setPaymentMethod,
    })),
  );

  return (
    <>
      <h2 className="text-new-Sub-Title">결제 수단</h2>
      <Spacer className="h-16" />
      <div className="flex flex-col gap-16">
        {PAYMENT_METHOD_OPTIONS.map((method) => (
          <div className="flex items-center gap-8" key={method.name}>
            <input
              type="radio"
              id={method.type}
              name="paymentMethod"
              className="checked:border-new-DTPurple-500 h-20 w-20 border-[2px] border-gray-200 bg-white checked:border-[6px]"
              onChange={() => {
                setPaymentMethod(method.type);
              }}
              checked={paymentMethod === method.type}
            />
            <label className="text-new-Body1-medium" htmlFor={method.type}>
              {method.name}
            </label>
          </div>
        ))}
      </div>
    </>
  );
};
