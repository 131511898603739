import React, { useEffect } from 'react';

import { useGetUser } from 'database';
import { useRouter } from 'next/router';
import { cn } from 'tailwind-config';

type UserRequirementLevel = 'public' | 'non-member' | 'member';

export const PageBase = ({
  children,
  userRequirementLevel = 'public',
  className,
}: {
  children?: React.ReactNode | React.ReactNode[];
  userRequirementLevel?: UserRequirementLevel;
  className?: string;
}) => {
  const { user, isLoading } = useGetUser();
  const router = useRouter();

  const shouldRedirect =
    (userRequirementLevel === 'member' && (!user || user.provider === 'non-member')) ||
    (userRequirementLevel === 'non-member' && !user);

  useEffect(() => {
    if (isLoading) return;

    if (shouldRedirect) {
      router.replace(`/login?redirectUrl=${encodeURIComponent(router.asPath)}`);
    }
  }, [isLoading, shouldRedirect]);

  if (shouldRedirect || (userRequirementLevel !== 'public' && isLoading)) return null;

  return <div className={cn('h-full', className)}>{children}</div>;
};
