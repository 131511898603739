import { useEffect, useState } from 'react';

import { useGetUser } from '@db/collections';
import { NewIcon, Spacer, useToast } from 'design-system';
import { isEmpty } from 'lodash-es';
import { cn } from 'tailwind-config';
import { useShallow } from 'zustand/react/shallow';

import { Popover } from '@components';
import { NeedLoginBottomSheet } from '@components/NeedLoginBottomSheet/NeedLoginBottomSheet';
import { useMergedLessonInfoOnTest } from '@components/ShortReservationFunnel/hooks/use-is-reservation-funnel-test';
import { useGetNonMemberFlag } from '@pages/academy/[id]/application';

import { usePurchaseData } from '../../Purchase.store';
import { PostCodeBottomSheet } from './components/PostCode';

export const formatPhoneNumber = (input: string) => {
  // 모든 하이픈을 제거합니다.
  let numbers = input.replace(/-/g, '');

  // 최대 11자리까지만 허용합니다.
  numbers = numbers.substring(0, 11);

  // 숫자를 형식에 맞게 하이픈을 추가합니다.
  const parts = [];
  if (numbers.length > 3) {
    parts.push(numbers.substring(0, 3));
    if (numbers.length > 7) {
      parts.push(numbers.substring(3, 7));
      parts.push(numbers.substring(7, 11));
    } else {
      parts.push(numbers.substring(3));
    }
  } else {
    parts.push(numbers);
  }

  return parts.join('-'); // 하이픈으로 조각들을 연결합니다.
};

const getAge = (birthday: string) => {
  const today = new Date();
  const birthDate = new Date(birthday);
  return today.getFullYear() - birthDate.getFullYear();
};

const useGetVisitorTooltipCondition = () => {
  const { data: user } = useGetUser();
  const { lessonInfo: funnelLessonInfo, academy } = usePurchaseData(
    useShallow((state) => ({
      lessonInfo: state.lessonInfo,
      academy: state.academy,
    })),
  );

  const lessonInfo = useMergedLessonInfoOnTest({
    academyId: academy?.id,
    funnelLessonInfo,
  });

  if (!user || !user.birthday || !lessonInfo) return false;

  const age = getAge(user.birthday);

  // 40살 이상, 면허가 1종보통,2종보통일 경우
  return (
    age >= 40 &&
    (lessonInfo.lessonCode === 'ONE_MANUAL_NEW' ||
      lessonInfo.lessonCode === 'ONE_MANUAL_ROAD' ||
      lessonInfo.lessonCode === 'TWO_AUTO_NEW' ||
      lessonInfo.lessonCode === 'TWO_AUTO_ROAD' ||
      lessonInfo.lessonCode === 'ONE_AUTO_NEW' ||
      lessonInfo.lessonCode === 'ONE_AUTO_ROAD')
  );
};

export const VisitorInfo = ({ addressInput = true }: { addressInput?: boolean }) => {
  const { user } = useGetUser();
  const isVisitorTooltipCondition = useGetVisitorTooltipCondition();
  const [isVisitorTooltipOpen, setIsVisitorTooltipOpen] = useState(true);
  const { setVisitorInfo, visitorInfo } = usePurchaseData(
    useShallow((state) => ({
      setVisitorInfo: state.setVisitorInfo,
      visitorInfo: state.visitorInfo,
    })),
  );

  const { openToast } = useToast();

  // const flag = useFeatureFlagVariantKey('non-member');
  const flag = useGetNonMemberFlag();

  useEffect(() => {
    if (!user || visitorInfo.name !== '') return;

    setVisitorInfo({
      name: !isEmpty(user.realName) && user.realName ? user.realName : '-',
      phoneNum: user.phoneNum,
      birthday: user.birthday ?? '',
    });
  }, [user]);

  const { name: visitorName, phoneNum: visitorPhoneNum, birthday: visitorBirthday } = visitorInfo;

  const isSameWithUser =
    !user || visitorName === '-' || visitorName === ''
      ? false
      : visitorName === user.realName && visitorPhoneNum === user.phoneNum;

  return (
    <>
      <div className="flex items-center justify-between">
        <h2 className="text-new-Sub-Title">
          <span className="text-new-DTRed-400">*</span>
          수강생 정보
        </h2>

        <div>
          {(!user || user.realName) && (
            <NeedLoginBottomSheet
              disable={flag === 'test'}
              title="예약을 위해 추가 정보가 필요해요."
              description="지금까지 입력한 정보는 그대로 유지돼요."
            >
              <div className="flex flex-col">
                <div
                  className="flex items-center gap-4"
                  onClick={() => {
                    if (!user) {
                      if (flag === 'test') {
                        openToast({
                          title: '예약자 정보를 먼저 인증해주세요.',
                        });
                      }
                      return;
                    }

                    if (isSameWithUser) {
                      setVisitorInfo({
                        name: '',
                        phoneNum: '',
                        birthday: '',
                      });
                    } else {
                      setVisitorInfo({
                        name: user.realName ?? '-',
                        phoneNum: user.phoneNum,
                        birthday: user.birthday ?? '',
                      });
                    }
                  }}
                >
                  <NewIcon
                    icon="chekincircle-filled"
                    size={20}
                    className={cn(isSameWithUser ? 'fill-new-DTPurple-500' : 'fill-new-gray-400')}
                  />
                  <p className="text-new-Body1-medium">예약자와 동일</p>
                </div>
                <Popover
                  content={'예약자와 수강생 정보를 확인해주세요.'}
                  open={isVisitorTooltipOpen && isVisitorTooltipCondition}
                  onOpenChange={setIsVisitorTooltipOpen}
                  color="purple"
                  align="end"
                  sideOffset={4}
                >
                  <div className="h-[0.01px]" />
                </Popover>
              </div>
            </NeedLoginBottomSheet>
          )}
        </div>
      </div>
      <Spacer className="h-4" />
      <p className="text-new-Body2-medium text-new-gray-500">
        실제 수강하시는 분의 정보를 입력해 주세요.
      </p>
      <Spacer className="h-16" />
      <NeedLoginBottomSheet
        disable={flag === 'test'}
        title="예약을 위해 추가 정보가 필요해요."
        description="지금까지 입력한 정보는 그대로 유지돼요."
      >
        <div>
          <p className="text-new-Caption1-bold text-new-gray-600">이름</p>
          <Spacer className="h-8" />
          <div
            onClick={() => {
              if (!user && flag === 'test') {
                openToast({
                  title: '예약자 정보를 먼저 인증해주세요.',
                });
                return;
              }
            }}
          >
            <input
              className={cn(
                'bg-new-gray-50 placeholder:text-new-gray-400 placeholder:text-new-Body1-medium text-new-Body1-bold border-1 border-new-gray-200 w-full rounded-[10px] px-16 py-11 focus-visible:outline-gray-900',
                !user && 'pointer-events-none',
              )}
              placeholder="홍길동"
              value={visitorName}
              onChange={(e) => {
                if (!user) return;

                setVisitorInfo({
                  ...visitorInfo,
                  name: e.target.value,
                });
              }}
            />
          </div>
          <Spacer className="h-16" />
          <p className="text-new-Caption1-bold text-new-gray-600">휴대폰 번호</p>
          <Spacer className="h-8" />

          <div
            onClick={() => {
              if (!user && flag === 'test') {
                openToast({
                  title: '예약자 정보를 먼저 인증해주세요.',
                });
                return;
              }
            }}
          >
            <input
              className={cn(
                'bg-new-gray-50 placeholder:text-new-gray-400 placeholder:text-new-Body1-medium text-new-Body1-bold border-1 border-new-gray-200 w-full rounded-[10px] px-16 py-11 focus-visible:outline-gray-900',
                !user && 'pointer-events-none',
              )}
              placeholder="010-1234-5678"
              maxLength={13}
              type="tel"
              pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"
              value={formatPhoneNumber(visitorPhoneNum)}
              onChange={(e) => {
                if (!user) return;

                setVisitorInfo({
                  ...visitorInfo,
                  phoneNum: e.target.value.replace(/\D/g, ''),
                });
              }}
            />
          </div>
        </div>
      </NeedLoginBottomSheet>
      {addressInput && (
        <>
          <Spacer className="h-16" />
          <p className="text-new-Caption1-bold text-new-gray-600">주소</p>
          <Spacer className="h-8" />
          <PostCodeBottomSheet
            popover={({ isOpen, onClose }) => (
              <Popover
                content={'이 주소가 맞나요?'}
                open={isOpen}
                asChild
                color="black"
                align="start"
                side="bottom"
                alignOffset={-20}
                sideOffset={4}
                onClickClose={onClose}
              >
                <div className="absolute bottom-0 left-20 h-1 w-1" />
              </Popover>
            )}
          >
            {(address) => (
              <div
                onClick={(e) => {
                  if (!user && flag === 'test') {
                    openToast({
                      title: '예약자 정보를 먼저 인증해주세요.',
                    });
                    e.stopPropagation();
                    return;
                  }
                }}
              >
                <input
                  className={cn(
                    'bg-new-gray-50 placeholder:text-new-gray-400 placeholder:text-new-Body1-medium text-new-Body1-bold w-full rounded-[10px] px-16 py-12',
                    'border-1 border-new-gray-200 pointer-events-none',
                  )}
                  placeholder="서울시 마포구 백범로 31길 21"
                  value={address}
                />
                <NewIcon
                  icon="magnifier-outlined"
                  size={20}
                  className="absolute-items-center fill-new-gray-400 right-16"
                />
              </div>
            )}
          </PostCodeBottomSheet>
        </>
      )}
    </>
  );
};
