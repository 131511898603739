import React, { ComponentPropsWithoutRef, forwardRef } from 'react';

import { cn } from 'tailwind-config';

export const PurchaseDivider = forwardRef<
  HTMLDivElement,
  { children: React.ReactNode } & ComponentPropsWithoutRef<'div'>
>(({ children, ...props }, ref) => {
  return (
    <div
      {...props}
      // children이 Null 일 때 hidden으로 설정하기 위해 Divider를 가상선택자로 구현
      className={cn(
        "after:bg-new-gray-100 relative pb-32 pt-40 after:absolute after:bottom-[-6px] after:left-0 after:mx-[-16px] after:inline-block after:h-8 after:w-[calc(100%+32px)] after:content-[''] empty:hidden",
        props.className,
      )}
      ref={ref}
    >
      {children}
    </div>
  );
});
