import React, { ComponentProps, MutableRefObject, useEffect, useRef } from 'react';

import { Spacer } from 'design-system';
import { cn } from 'tailwind-config';
import { useShallow } from 'zustand/react/shallow';

import { useIsIOSApp } from '@templates/Application/hooks/use-is-ios-app';

import { Funnel } from '@hooks/use-funnel';

import { usePurchaseData } from './Purchase.store';
import {
  Coupons,
  Payment,
  PurchaseAgreement,
  PurchaseBottom,
  ReservationInfo,
  ShuttleBusPreference,
  VisitorInfo,
} from './components';
import { PriceBox } from './components/PriceBox';
import { RefundPolicyBanner } from './components/PurchaseAgreement/components';
import { PurchaseDivider } from './components/PurchaseDivider';
import { UserInfo } from './components/UserInfo/UserInfo';

const useInitializePurchaseData = ({
  academy,
  lessonInfo,
  selectedTime,
  selectedRange,
  paymentType,
}: {
  academy: Academy;
  lessonInfo: LessonInfo;
  selectedTime: SelectedTime | null;
  selectedRange: SelectedRange | null;
  paymentType?: Academy['paymentType'];
}) => {
  const { setAcademy, setLessonInfo, setselectedTime, setSelectedRange, setIs전액결제 } =
    usePurchaseData(
      useShallow((state) => ({
        setAcademy: state.setAcademy,
        setLessonInfo: state.setLessonInfo,
        setselectedTime: state.setselectedTime,
        setSelectedRange: state.setSelectedRange,
        setIs전액결제: state.setIs전액결제,
      })),
    );

  useEffect(() => {
    setAcademy(academy);
    setIs전액결제((paymentType ?? academy.paymentType) === 'entire');
    setLessonInfo(lessonInfo);
    setselectedTime(selectedTime);
    setSelectedRange(selectedRange);
  }, [academy, lessonInfo, selectedTime, selectedRange]);
};

const RequiredKeys = ['userInfo', 'visitorInfo', 'agreement'] as const;

export type RequiredKey = (typeof RequiredKeys)[number];

/**
 * Primitive 를 따로 선언하여 내부 컴포넌트를 자유롭게 사용하도록 함.
 */
export const PurchaseScreenPrimitive = ({
  academy,
  lessonInfo,
  selectedTime,
  selectedRange,
  paymentType,
  children,
}: {
  academy: Academy;
  lessonInfo: LessonInfo;
  selectedTime: SelectedTime | null;
  selectedRange: SelectedRange | null;
  paymentType?: Academy['paymentType'];
  children: (refs: MutableRefObject<Record<RequiredKey, HTMLDivElement | null>>) => React.ReactNode;
}) => {
  useInitializePurchaseData({ academy, lessonInfo, selectedTime, selectedRange, paymentType });

  /**
   * 결제 동의 등 결제 전 필수로 체크되어야 하는 요소들이 있음
   * 해당 컴포넌트로 스크롤 해야할 때 사용
   */
  const refs = useRef<Record<RequiredKey, HTMLDivElement | null>>(
    RequiredKeys.reduce((acc, key) => {
      acc[key as RequiredKey] = null;
      return acc;
    }, {} as Record<RequiredKey, HTMLDivElement | null>),
  );

  return <>{children(refs)}</>;
};

export const Purchase = (
  props: Omit<ComponentProps<typeof PurchaseScreenPrimitive>, 'children'>,
) => {
  const isIOSApp = useIsIOSApp();

  return (
    <PurchaseScreenPrimitive {...props}>
      {(refs) => (
        <>
          <PurchaseDivider className="pt-24">
            <ReservationInfo />
          </PurchaseDivider>
          <PurchaseDivider
            ref={(node) => {
              node && (refs.current.userInfo = node);
            }}
          >
            <UserInfo />
          </PurchaseDivider>
          <PurchaseDivider
            ref={(node) => {
              node && (refs.current.visitorInfo = node);
            }}
          >
            <VisitorInfo />
          </PurchaseDivider>
          <PurchaseDivider>
            <ShuttleBusPreference />
          </PurchaseDivider>
          <PurchaseDivider>
            <Coupons />
          </PurchaseDivider>
          <PurchaseDivider>
            <Payment />
          </PurchaseDivider>
          <PurchaseDivider>
            <PriceBox />
          </PurchaseDivider>
          <Spacer className="h-32" />
          <div
            ref={(node) => {
              node && (refs.current.agreement = node);
            }}
          >
            <PurchaseAgreement />
          </div>
          <Spacer className="h-20" />
          <RefundPolicyBanner />
          <Spacer className="h-80" />
          <Funnel.Bottom className={cn('flex bg-white', isIOSApp ? 'pb-32' : '')}>
            <PurchaseBottom requiredComponents={refs.current} />
          </Funnel.Bottom>
        </>
      )}
    </PurchaseScreenPrimitive>
  );
};
