import { useEffect, useRef, useState } from 'react';

import { useGetUser } from '@db/collections';
import axios from 'axios';
import { NewBoxButton, Spacer, useToast } from 'design-system';
import { auth } from 'firebase-config';
import { browserSessionPersistence, setPersistence, signInWithCustomToken } from 'firebase/auth';
import { BACKEND_URL } from 'shared-values';
import { cn } from 'tailwind-config';

import { useGetNonMemberFlag } from '@pages/academy/[id]/application';

import { api } from '@apis/hc';

import { getDeviceByUserAgent, getRandomCode } from '@utils/Common';

export const LoadingSpinner = ({
  size = 20,
  borderWidth = 2.5,
}: {
  size?: number;
  borderWidth?: number;
}) => {
  return (
    <div
      style={{
        height: `${size}px`,
        width: `${size}px`,
        borderWidth: `${borderWidth}px`,
      }}
      className={cn(`border-new-gray-300 border-t-new-DTYellow-400 animate-spin rounded-full`)}
    ></div>
  );
};

export const formatPhoneNumber = (input: string) => {
  let numbers = input.replace(/-/g, '');
  numbers = numbers.substring(0, 11);
  const parts = [];
  if (numbers.length > 3) {
    parts.push(numbers.substring(0, 3));
    if (numbers.length > 7) {
      parts.push(numbers.substring(3, 7));
      parts.push(numbers.substring(7, 11));
    } else {
      parts.push(numbers.substring(3));
    }
  } else {
    parts.push(numbers);
  }
  return parts.join('-');
};

// MM:SS 형식으로 시간을 포맷하는 함수
const formatTime = (ms: number) => {
  const totalSeconds = Math.ceil(ms / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

const CODE_EXPIRATION_TIME = 3 * 60 * 1000;
const COOLDOWN_EXPIRATION_TIME = 3 * 60 * 1000;
const MAX_ATTEMPT_COUNT = 5;

const RemainingTime = ({ code }: { code: string }) => {
  const [remainingTime, setRemainingTime] = useState(0);

  // 인증번호가 발송되면 만료 시간을 3분 후로 설정하고, 남은 시간을 업데이트하는 타이머 설정
  useEffect(() => {
    if (!code) return;

    const expiration = Date.now() + CODE_EXPIRATION_TIME;

    const interval = setInterval(() => {
      const timeLeft = expiration - Date.now();
      if (timeLeft <= 0) {
        setRemainingTime(0);
        clearInterval(interval);
      } else {
        setRemainingTime(timeLeft);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [code]);

  return (
    <span className="text-new-Caption1-medium text-new-DTRed-400 absolute right-16 top-1/2 z-10 -translate-y-1/2">
      {formatTime(remainingTime)}
    </span>
  );
};

export const UserInfoInput = () => {
  const { user } = useGetUser();
  const { openToast } = useToast();

  const [name, setName] = useState('');
  const [phoneNum, setPhoneNum] = useState('');
  const [certificationCode, setCertificationCode] = useState('');
  const [sentCertificationCode, setSentCertificationCode] = useState(false);
  const [isSendCertificationCodeLoading, setIsSendCertificationCodeLoading] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [code, setCode] = useState('');

  // 인증번호 만료와 관련된 상태
  const [codeExpirationTime, setCodeExpirationTime] = useState(0);

  // 재전송 횟수를 추적하는 상태 (재전송 5회 초과 시 쿨다운)
  const [resendAttempts, setResendAttempts] = useState(0);
  const [cooldownExpiry, setCooldownExpiry] = useState(0);

  const certificationCodeRef = useRef<HTMLInputElement>(null);

  // 인증번호 발송 시 입력창에 포커스 주기
  useEffect(() => {
    if (sentCertificationCode && certificationCodeRef.current) {
      certificationCodeRef.current.focus();
    }
  }, [sentCertificationCode]);

  const isCertificated = user && user.provider === 'non-member';

  if (user && user.provider !== 'non-member') return null;

  const sendCertificationCode = async () => {
    if (phoneNum.length !== 11) {
      openToast({
        title: '휴대폰 번호 11자리를 입력해주세요.',
      });
      return;
    }

    if (name.trim().length === 0) {
      openToast({
        title: '이름을 입력해주세요.',
      });
      return;
    }

    if (new Date() < new Date(cooldownExpiry)) {
      openToast({
        title: '3분 후에 다시 시도해주세요.',
      });
      return;
    }
    // 재전송 횟수에 따른 쿨다운 체크
    if (resendAttempts >= MAX_ATTEMPT_COUNT) {
      openToast({
        title: '3분 후에 다시 시도해주세요.',
      });
      setCooldownExpiry(new Date().getTime() + COOLDOWN_EXPIRATION_TIME);
      setResendAttempts(0);
      return;
    }

    if (isSendCertificationCodeLoading) return;

    setIsSendCertificationCodeLoading(true);

    try {
      const newCode = getRandomCode();
      await axios.get(`${BACKEND_URL}/sms?phoneNum=${phoneNum}&code=${newCode}`);

      setSentCertificationCode(true);
      setCode(newCode);
      // 인증번호 발송 시 만료시간 설정 (3분)
      setCodeExpirationTime(Date.now() + CODE_EXPIRATION_TIME);
      // 재전송 횟수 증가
      setResendAttempts((prev) => prev + 1);
    } catch (error) {
      openToast({
        title: '인증번호 전송에 실패했습니다. 다시 시도해주세요.',
      });
    } finally {
      setIsSendCertificationCodeLoading(false);
      setTimeout(() => {
        setCertificationCode('');
        certificationCodeRef.current?.focus();
      }, 500);
    }
  };

  const onSubmit = async () => {
    if (isLoginLoading) return;

    // 인증번호 만료 여부 확인
    if (Date.now() > codeExpirationTime) {
      openToast({
        title: '만료된 인증번호입니다.',
      });
      return;
    }

    if (certificationCode !== code) {
      openToast({
        title: '잘못된 인증번호입니다.',
      });
      return;
    }

    setIsLoginLoading(true);
    const device = getDeviceByUserAgent();

    const res = await api.auth['phonenum-signin'].$post({
      json: {
        name: name,
        phoneNum: phoneNum,
        device: device ?? '',
      },
    });
    const { token } = await res.json();

    await setPersistence(auth, browserSessionPersistence);
    await signInWithCustomToken(auth, token);

    openToast({
      title: '인증되었습니다.',
    });
    setIsLoginLoading(false);
  };

  return (
    <>
      <p className="text-new-Caption1-bold text-new-gray-600">이름</p>
      <Spacer className="h-8" />
      <input
        className={cn(
          'bg-new-gray-50 placeholder:text-new-gray-400 placeholder:text-new-Body1-medium text-new-Body1-bold border-1 border-new-gray-200 w-full rounded-[10px] px-16 py-11 focus-visible:outline-gray-900',
          user && 'pointer-events-none',
        )}
        placeholder="홍길동"
        value={user?.name ? user.realName : name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
      <Spacer className="h-16" />
      <p className="text-new-Caption1-bold text-new-gray-600">휴대폰 번호</p>
      <Spacer className="h-8" />
      <div className="flex items-center gap-16">
        <input
          className={cn(
            'bg-new-gray-50 placeholder:text-new-gray-400 placeholder:text-new-Body1-medium text-new-Body1-bold border-1 border-new-gray-200 w-full rounded-[10px] px-16 py-11 focus-visible:outline-gray-900',
            user && 'pointer-events-none',
          )}
          placeholder="010-1234-5678"
          maxLength={13}
          type="tel"
          inputMode="numeric"
          pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"
          value={user?.phoneNum ? formatPhoneNumber(user.phoneNum) : formatPhoneNumber(phoneNum)}
          onChange={(e) => {
            setPhoneNum(e.target.value.replace(/\D/g, ''));
          }}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              await sendCertificationCode();
            }
          }}
        />
        {!isCertificated && (
          <NewBoxButton
            label={
              isSendCertificationCodeLoading ? (
                <LoadingSpinner />
              ) : sentCertificationCode ? (
                '재전송'
              ) : (
                '인증번호 받기'
              )
            }
            size="medium"
            styles="filled-black"
            className="h-[48px]"
            onClick={sendCertificationCode}
          />
        )}
      </div>
      {!isCertificated && (
        <div className={cn(!sentCertificationCode && 'hidden')}>
          <Spacer className="h-4" />
          <p className="text-new-Caption1-medium text-new-DTRed-400">
            남은 횟수{' '}
            {new Date() < new Date(cooldownExpiry) ? 0 : MAX_ATTEMPT_COUNT - resendAttempts}회
          </p>
          <Spacer className="h-16" />
          <p className="text-new-Caption1-bold text-new-gray-600">인증 코드</p>
          <Spacer className="h-8" />
          <div className="relative flex items-center gap-16">
            {/* 입력창을 감싸는 relative 컨테이너 */}
            <div className="relative w-full">
              <input
                className={cn(
                  'bg-new-gray-50 placeholder:text-new-gray-400 placeholder:text-new-Body1-medium text-new-Body1-bold border-1 border-new-gray-200 w-full rounded-[10px] px-16 py-11 focus-visible:outline-gray-900',
                )}
                placeholder="인증번호 6자리"
                maxLength={6}
                type="number"
                inputMode="numeric"
                value={certificationCode}
                onChange={(e) => {
                  setCertificationCode(e.target.value.replace(/\D/g, '').slice(0, 6));
                }}
                onKeyDown={async (e) => {
                  if (e.key === 'Enter') {
                    await onSubmit();
                  }
                }}
                ref={certificationCodeRef}
              />
              {/* 남은 시간 카운트 (absolute, 오른쪽 16px, 수직 중앙) */}
              {sentCertificationCode && <RemainingTime code={code} />}
            </div>
            <NewBoxButton
              label={isLoginLoading ? <LoadingSpinner /> : '인증하기'}
              size="medium"
              styles="filled-black"
              className="h-[48px]"
              onClick={onSubmit}
            />
          </div>
        </div>
      )}
    </>
  );
};

export const UserInfo = () => {
  // const flag = useFeatureFlagVariantKey('non-member');
  const flag = useGetNonMemberFlag();

  const { user } = useGetUser();

  if (flag !== 'test' || (user && user.provider !== 'non-member')) return null;

  return (
    <>
      <div className="flex items-center justify-between">
        <h2 className="text-new-Sub-Title">
          <span className="text-new-DTRed-400">*</span>
          예약자 정보
        </h2>
      </div>
      <Spacer className="h-16" />
      <UserInfoInput />
    </>
  );
};
